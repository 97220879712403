import {
  SIGNUP_PENDING,
  SIGNUP_SUCCESS,
  SIGNUP_ERROR,
  VERIFY_PENDING,
  VERIFY_SUCCESS,
  VERIFY_ERROR,
  CLEAR_ALERT,
} from '../actions/actionTypes';

const initialState = {
  pending: false,
  error: null,
};

const register = (state = initialState, action) => {
  switch (action.type) {
    case SIGNUP_PENDING:
      return {
        pending: true,
        user: action.user,
      };
    case SIGNUP_SUCCESS:
      return {
        ...state,
        pending: false,
        success: action.user,
      };
    case SIGNUP_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
        alert: action.alert,
      };
    case VERIFY_PENDING:
      return {
        pending: true,
        data: action.data,
      };
    case VERIFY_SUCCESS:
      return {
        pending: false,
        success: action.data,
      };
    case VERIFY_ERROR:
      return {
        pending: false,
        error: action.error,
        alert: action.alert,
      };
    case CLEAR_ALERT:
      if (action.success) {
        state.success = null;
      }
      if (action.error) {
        state.error = null;
      }
      return { ...state };
    default:
      return state;
  }
};

export default register;
