import React from 'react';
import { Switch, Redirect } from 'react-router-dom';

import { PublicRoute, PrivateRoute } from './components';
import { Main as MainLayout, Empty as EmptyLayout } from './layouts';

import {
  Ticket as TicketView,
  Account as AccountView,
  Login as LoginView,
  SignUp as SignUpView,
  Verify as VerifyView,
  NotFound as NotFoundView,
} from './views';

const Routes = () => {
  return (
    <Switch>
      <Redirect exact from='/' to='/ticket' />
      <PrivateRoute
        component={TicketView}
        exact
        layout={MainLayout}
        path='/ticket'
      />
      <PrivateRoute
        component={AccountView}
        exact
        layout={MainLayout}
        path='/user/account'
      />
      <PublicRoute
        component={LoginView}
        exact
        layout={EmptyLayout}
        path='/user/login'
      />
      <PublicRoute
        component={SignUpView}
        exact
        layout={EmptyLayout}
        path='/user/signup'
      />
      <PublicRoute
        component={VerifyView}
        exact
        layout={EmptyLayout}
        path='/user/verify/:token'
      />
      <PublicRoute
        component={NotFoundView}
        exact
        layout={EmptyLayout}
        path='/not-found'
      />
      <Redirect to='/not-found' />
    </Switch>
  );
};

export default Routes;
