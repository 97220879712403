import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import { Grid, Box, Button, Typography, Container } from '@material-ui/core';
import CssBaseline from '@material-ui/core/CssBaseline';
import { Link as RouterLink } from 'react-router-dom';
import PermIdentity from '@material-ui/icons/PermIdentity';
import { withStyles } from '@material-ui/core/styles';
import Footer from '../../layouts/Main/components/Footer';
import { userActions, showAlert } from '../../store/actions';

const useStyles = (theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    color: '#272727',
  },
  title: {
    display: 'flex',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  icon: {
    fontSize: '2em',
    marginRight: 4,
  },
});

class SignUp extends Component {
  state = {
    username: '',
    email: '',
    password: '',
    password_confirmation: '',
    language: 'es',
  };

  handleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  handleSubmit = (event) => {
    const { signUp, showAlert } = this.props;

    setTimeout(() => {
      if (this.props.register.error) {
        var errorAcum = [];
        Object.entries(this.props.register.error).map((error, key) => {
          error[1].map((v) => {
            errorAcum.push(
              <li key={key}>
                {error[0]}: {v}
              </li>
            );
            return v;
          });
          return errorAcum;
        });
        showAlert({
          type: 'error',
          title: this.props.register.alert.title,
          content: errorAcum,
        });
      }
    }, 300);

    event.preventDefault();
    signUp(
      this.state.username,
      this.state.email,
      this.state.password,
      this.state.password_confirmation,
      this.state.language
    );
  };

  componentDidMount() {
    ValidatorForm.addValidationRule('isPasswordMatch', (value) => {
      if (value !== this.state.password) {
        return false;
      }
      return true;
    });
  }

  componentWillUnmount() {
    const { clearAlert } = this.props;
    ValidatorForm.removeValidationRule('isPasswordMatch');
    clearAlert(false, true);
  }

  render() {
    const { classes } = this.props;

    return (
      <Container component='main' maxWidth='xs'>
        <CssBaseline />
        <div className={classes.paper}>
          <Typography variant='h5' className={classes.title}>
            <PermIdentity className={`${classes.icon} primary-text`} /> Registro
          </Typography>
          <ValidatorForm
            className={classes.form}
            onSubmit={this.handleSubmit}
            noValidate
          >
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextValidator
                  autoComplete='username'
                  name='username'
                  value={this.state.username}
                  onChange={this.handleChange}
                  variant='outlined'
                  required
                  fullWidth
                  id='username'
                  label='Nombre de usuario'
                  autoFocus
                  validators={['required', 'minStringLength:4']}
                  errorMessages={[
                    'Este campo es requerido',
                    'El nombre de usuario debe contener al menos 4 caracteres',
                  ]}
                />
              </Grid>
              <Grid item xs={12}>
                <TextValidator
                  autoComplete='email'
                  name='email'
                  value={this.state.email}
                  onChange={this.handleChange}
                  variant='outlined'
                  required
                  fullWidth
                  id='email'
                  label='Correo electrónico'
                  validators={['required', 'isEmail']}
                  errorMessages={[
                    'Este campo es requerido',
                    'El correo electrónico no es válido',
                  ]}
                />
              </Grid>
              <Grid item xs={12}>
                <TextValidator
                  variant='outlined'
                  required
                  fullWidth
                  id='password'
                  label='Contraseña'
                  name='password'
                  value={this.state.password}
                  onChange={this.handleChange}
                  type='password'
                  autoComplete='password'
                  validators={['required', 'minStringLength:8']}
                  errorMessages={[
                    'Este campo es requerido',
                    'La contraseña debe contener al menos 8 caracteres',
                  ]}
                />
              </Grid>
              <Grid item xs={12}>
                <TextValidator
                  variant='outlined'
                  required
                  fullWidth
                  id='password_confirmation'
                  label='Confirmar contraseña'
                  name='password_confirmation'
                  value={this.state.password_confirmation}
                  onChange={this.handleChange}
                  type='password'
                  autoComplete='password_confirmation'
                  validators={['required', 'isPasswordMatch']}
                  errorMessages={[
                    'Este campo es requerido',
                    'Las contraseñas no coiciden',
                  ]}
                />
              </Grid>
            </Grid>
            <Button
              type='submit'
              variant='contained'
              color='primary'
              className={`${classes.submit} btn-primary`}
            >
              Registrarme
            </Button>
            <Grid container justify='flex-end'>
              <Grid item>
                <RouterLink to='/user/login' variant='body2'>
                  ¿Ya tienes cuenta?. Inicia sesión
                </RouterLink>
              </Grid>
            </Grid>
          </ValidatorForm>
        </div>
        <Box mt={5}>
          <Footer />
        </Box>
      </Container>
    );
  }
}

const mapStateToProps = (state) => ({
  register: state.register,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      signUp: userActions.signUp,
      clearAlert: userActions.clearAlert,
      showAlert,
    },
    dispatch
  );

const connectedSignUp = connect(mapStateToProps, mapDispatchToProps)(SignUp);
export default withStyles(useStyles)(connectedSignUp);
