const Styles = (theme) => ({
  root: {
    height: '100vh',
  },
  image: {
    backgroundImage: 'url(https://source.unsplash.com/random)',
    backgroundRepeat: 'no-repeat',
    backgroundColor:
      theme.palette.type === 'dark'
        ? theme.palette.grey[900]
        : theme.palette.grey[50],
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
  paper: {
    margin: theme.spacing(8, 4),
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  field: {
    background: theme.palette.white,
    borderRadius: 4,
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  footer: {
    justifyContent: 'center',
    display: 'flex',
  },
  [theme.breakpoints.only('xs')]: {
    gradient: {
      backgroundImage: `linear-gradient(${theme.palette.primary.bgGradient})`,
    },
    mainTitle: {
      fontWeight: 'bold',
      textAlign: 'left',
    },
    paper: {
      color: theme.palette.text.primaryText,
    },
    footer: {
      '& a': {
        color: theme.palette.text.primaryText,
      },
    },
  },
  [theme.breakpoints.up('sm')]: {
    paper: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
  },
});

export default Styles;
