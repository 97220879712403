export const userService = {
  login,
  logout,
  signUp,
  verify,
};

function login(email, password) {
  const apiUrl = process.env.REACT_APP_API_URL;
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ email, password }),
  };

  return fetch(`${apiUrl}/token/`, requestOptions)
    .then(handleResponse)
    .then((user) => {
      // store user details and jwt token in local storage to keep user logged in between page refreshes
      localStorage.setItem('user', JSON.stringify(user));

      return user;
    });
}

function logout() {
  localStorage.removeItem('user');
  return true;
}

async function signUp(
  username,
  email,
  password,
  password_confirmation,
  language
) {
  const apiUrl = process.env.REACT_APP_API_URL;
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Accept-Language': 'es',
    },
    body: JSON.stringify({
      username,
      email,
      password,
      password_confirmation,
      language,
    }),
  };
  var response = await fetch(`${apiUrl}/users/signup/`, requestOptions)
    .then(handleResponse)
    .then((data) => {
      return data;
    })
    .catch((error) => {
      return Promise.reject(error);
    });

  return response;
}

async function verify(token) {
  const apiUrl = process.env.REACT_APP_API_URL;
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ token }),
  };
  var response = await fetch(`${apiUrl}/users/verify/`, requestOptions)
    .then(handleResponse)
    .then((data) => {
      return data;
    })
    .catch((error) => {
      return Promise.reject(error);
    });

  return response;
}

function handleResponse(response) {
  return response.text().then((text) => {
    const data = text && JSON.parse(text);
    if (!response.ok) {
      if (response.status === 401) {
        logout();
      }

      const error = data || response.statusText;
      return Promise.reject(error);
    }

    return Promise.resolve(data);
  });
}
