import React, { Component } from 'react';
import { Router } from 'react-router-dom';
import { Chart } from 'react-chartjs-2';
import { ThemeProvider } from '@material-ui/styles';
import validate from 'validate.js';
import { history } from './helpers';
import SweetAlert from 'react-bootstrap-sweetalert';
import { getVisibleAlert } from './store/reducers/notifications';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Typography } from '@material-ui/core';

import { chartjs } from './helpers';
import theme from './theme';
import 'react-perfect-scrollbar/dist/css/styles.css';
import './assets/scss/index.scss';
import validators from './common/validators';
import Routes from './Routes';

Chart.helpers.extend(Chart.elements.Rectangle.prototype, {
  draw: chartjs.draw,
});

validate.validators = {
  ...validate.validators,
  ...validators,
};

class App extends Component {
  render() {
    const { visibleAlert } = this.props;
    return (
      <ThemeProvider theme={theme}>
        {visibleAlert && (
          <Typography component={'div'}>
            <SweetAlert {...visibleAlert}>{visibleAlert.content}</SweetAlert>
          </Typography>
        )}
        <Router history={history}>
          <Routes />
        </Router>
      </ThemeProvider>
    );
  }
}

App.propTypes = {
  visibleAlert: PropTypes.any,
};

const mapStateToProps = (state) => ({
  visibleAlert: getVisibleAlert(state.notifications),
});

export default connect(mapStateToProps, {})(App);
