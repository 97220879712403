import {
  FETCH_CITIES_PENDING,
  FETCH_CITIES_SUCCESS,
  FETCH_CITIES_ERROR,
} from '../actions/actionTypes';

const initialState = {
  pending: false,
  error: null,
  citiesList: [],
};

export default function cities(state = initialState, action) {
  switch (action.type) {
    case FETCH_CITIES_PENDING:
      return {
        ...state,
        pending: true,
      };
    case FETCH_CITIES_SUCCESS:
      return {
        ...state,
        pending: false,
        citiesList: action.payload,
      };
    case FETCH_CITIES_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
      };
    default:
      return state;
  }
}

export const getCities = (state) => state.citiesList;
export const getCitiesPending = (state) => state.pending;
export const getCitiesError = (state) => state.error;
