import {
  LOGIN_PENDING,
  LOGIN_SUCCESS,
  LOGIN_ERROR,
  LOGOUT_PENDING,
  LOGOUT_SUCCESS,
  LOGOUT_ERROR,
  CLEAR_ALERT,
} from '../actions/actionTypes';

let user = JSON.parse(localStorage.getItem('user'));
const initialState = user ? { loggedIn: true, user } : {};

const authentication = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_PENDING:
      return {
        loggingIn: true,
        user: action.user,
      };
    case LOGIN_SUCCESS:
      return {
        loggedIn: true,
        user: action.user,
      };
    case LOGIN_ERROR:
      return {
        ...state,
        loggedIn: false,
        error: action.error,
        alert: action.alert,
      };
    case LOGOUT_PENDING:
      return {
        loggingOut: true,
        user: action.user,
      };
    case LOGOUT_SUCCESS:
      return {};
    case LOGOUT_ERROR:
      return {
        ...state,
        error: action.error,
      };
    case CLEAR_ALERT:
      if (action.error) {
        state.error = null;
      }
      return { ...state };
    default:
      return state;
  }
};

export default authentication;
