import {
  FETCH_CITIES_PENDING,
  FETCH_CITIES_SUCCESS,
  FETCH_CITIES_ERROR,
} from './actionTypes';

// import config from '../../services/config';
export const citiesActions = {
  fetchCities,
};

function syncCities(apiUrl, token) {
  const requestOptions = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  };
  fetch(`${apiUrl}/cities/sync_from_source`, requestOptions)
    .then((res) => {
      return res;
    })
    .catch((error) => {
      console.log(error);
      // dispatch(fetchCitiesError(error));
    });
}

function fetchCities() {
  return (dispatch) => {
    const apiUrl = process.env.REACT_APP_API_URL;
    const token = JSON.parse(localStorage.user).access;

    dispatch(fetchCitiesPending());
    syncCities(apiUrl, token);
    const requestOptions = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    };
    fetch(`${apiUrl}/cities?page_size=100`, requestOptions)
      .then((res) => res.json())
      .then((res) => {
        if (res.error) {
          throw res.error;
        }
        var citiesList = res.map((e) => {
          return { label: e.name, value: e.uid };
        });
        dispatch(fetchCitiesSuccess(citiesList));
        return citiesList;
      })
      .catch((error) => {
        dispatch(fetchCitiesError(error));
      });
  };
  function fetchCitiesPending() {
    return { type: FETCH_CITIES_PENDING };
  }

  function fetchCitiesSuccess(payload) {
    return { type: FETCH_CITIES_SUCCESS, payload };
  }

  function fetchCitiesError(error) {
    return { type: FETCH_CITIES_ERROR, error: error };
  }
}
