import {
  LOGIN_PENDING,
  LOGIN_SUCCESS,
  LOGIN_ERROR,
  LOGOUT_PENDING,
  LOGOUT_SUCCESS,
  SIGNUP_PENDING,
  SIGNUP_SUCCESS,
  SIGNUP_ERROR,
  VERIFY_PENDING,
  VERIFY_SUCCESS,
  VERIFY_ERROR,
  CLEAR_ALERT,
} from './actionTypes';

import { userService } from '../../services';
import { history } from '../../helpers';

export const userActions = {
  login,
  logout,
  signUp,
  verify,
  clearAlert,
};

function login(email, password) {
  return (dispatch) => {
    dispatch(request({ email }));

    userService.login(email, password).then(
      (user) => {
        dispatch(success(user));
        history.push('/');
      },
      (error) => {
        var alert = {
          title: 'Inicio incorrecto',
        };
        dispatch(failure(error, alert));
      }
    );
  };

  function request(user) {
    return { type: LOGIN_PENDING, user };
  }
  function success(user) {
    return { type: LOGIN_SUCCESS, user };
  }
  function failure(error, alert) {
    return { type: LOGIN_ERROR, error, alert };
  }
}

function logout() {
  return (dispatch) => {
    dispatch(request());

    userService.logout();
    dispatch(success());
    history.push('/user/login');
  };
  // remove user from local storage to log user out

  function request() {
    return { type: LOGOUT_PENDING };
  }
  function success() {
    return { type: LOGOUT_SUCCESS };
  }
}

function signUp(username, email, password, passwordConfirmation, language) {
  return (dispatch) => {
    dispatch(request({ username, email }));

    userService
      .signUp(username, email, password, passwordConfirmation, language)
      .then(
        (data) => {
          data['title'] = 'Usuario registrado';
          data[
            'message'
          ] = `'${data.username}' hemos enviado las instrucciones a tu correo electrónico para activar tu cuenta.`;
          dispatch(success(data));
          history.push('/user/login');
        },
        (error) => {
          var alert = {
            title: 'Usuario no registrado',
          };
          dispatch(failure(error, alert));
        }
      );
  };

  function request(user) {
    return { type: SIGNUP_PENDING, user };
  }
  function success(user) {
    return { type: SIGNUP_SUCCESS, user };
  }
  function failure(error, alert) {
    return { type: SIGNUP_ERROR, error, alert };
  }
}

function verify(token) {
  return (dispatch) => {
    dispatch(request({ token }));
    userService
      .verify(token)
      .then((data) => {
        data['title'] = 'Cuenta verificada';
        dispatch(success(data));
        history.push('/user/login');
      })
      .catch((error) => {
        var alert = {
          title: 'Cuenta no verificada',
        };
        dispatch(failure(error, alert));
        history.push('/user/login');
      });
  };

  function request(data) {
    return { type: VERIFY_PENDING, data };
  }
  function success(data) {
    return { type: VERIFY_SUCCESS, data };
  }
  function failure(error, alert) {
    return { type: VERIFY_ERROR, error, alert };
  }
}

function clearAlert(success = true, error = true) {
  return { type: CLEAR_ALERT, success, error };
}
