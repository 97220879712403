import { combineReducers } from 'redux';
import authentication from './authentication';
import register from './register';
import cities from './cities';
import notifications from './notifications';

export default combineReducers({
  authentication,
  register,
  cities,
  notifications,
});
