import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Typography, Grid } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import Select from 'react-select';

import { citiesActions } from '../../store/actions';

const useStyles = (theme) => ({
  root: {
    padding: theme.spacing(4),
  },
  [theme.breakpoints.only('xs')]: {
    gradient: {
      backgroundImage: `linear-gradient(${theme.palette.primary.bgGradient})`,
    },
  },
});

class Ticket extends Component {
  componentDidMount() {
    this.props.fetchCities();
    document.body.className = this.props.classes.gradient;
  }

  componentWillUnmount() {
    document.body.className = '';
  }

  render() {
    const { classes, cities } = this.props;

    return (
      <Typography component={'div'} className={classes.root}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <Select
              // value={selectedOption}
              // onChange={this.handleCityChange}
              options={cities}
              placeholder='Saliendo de'
            />
          </Grid>
        </Grid>
        <Grid>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <Select
                // value={selectedOption}
                // onChange={this.handleCityChange}
                options={cities}
                placeholder='Llegando a'
              />
            </Grid>
          </Grid>
        </Grid>
      </Typography>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    cities: state.cities.citiesList,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      fetchCities: citiesActions.fetchCities,
    },
    dispatch
  );

const conectedTicket = connect(mapStateToProps, mapDispatchToProps)(Ticket);
export default withStyles(useStyles)(conectedTicket);
