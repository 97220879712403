import React from 'react';
import { Route, Redirect } from 'react-router';

const PrivateRoute = ({ component: Component, layout: Layout, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      localStorage.getItem('user') ? (
        <Layout>
          <Component {...props} />
        </Layout>
      ) : (
        <Redirect
          to={{ pathname: '/user/login', state: { from: props.location } }}
        />
      )
    }
  />
);

export default PrivateRoute;
