import React, { Component } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import {
  Avatar,
  CssBaseline,
  Button,
  Paper,
  Box,
  Grid,
  Typography,
  Hidden,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';

import Styles from './styles';
import { userActions, showAlert } from '../../store/actions';
import { Footer } from '../../layouts/Main/components';

class Login extends Component {
  state = {
    email: '',
    password: '',
  };

  handleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  handleSubmit = (event) => {
    const { showAlert, login } = this.props;
    event.preventDefault();
    setTimeout(() => {
      const { authentication } = this.props;
      if (authentication.error) {
        showAlert({
          type: 'error',
          title: authentication.alert.title,
          content: authentication.error.detail,
        });
      }
    }, 300);
    login(this.state.email, this.state.password);
  };

  componentDidMount() {
    const { showAlert, register } = this.props;
    if (register.success) {
      showAlert({
        type: 'success',
        title: register.success.title,
        content: register.success.message,
      });
    }
    if (register.error) {
      var errorAcum = [];
      Object.entries(this.props.register.error).map((error, key) => {
        error[1].map((v) => {
          errorAcum.push(<li key={key}>{v}</li>);
          return v;
        });
        return errorAcum;
      });
      showAlert({
        type: 'error',
        title: register.alert.title,
        content: errorAcum,
      });
    }
  }

  componentWillUnmount() {
    const { clearAlert } = this.props;
    clearAlert();
  }

  render() {
    const { classes } = this.props;
    return (
      <Grid container component='main' className={classes.root}>
        <CssBaseline />
        <Grid item xs={false} sm={4} md={7} className={classes.image} />
        <Grid
          item
          xs={12}
          sm={8}
          md={5}
          component={Paper}
          elevation={6}
          square
          className={classes.gradient}
        >
          <div className={classes.paper}>
            <Hidden only='xs'>
              <Avatar className={classes.avatar}>
                <LockOutlinedIcon />
              </Avatar>
            </Hidden>
            <Typography
              component='h1'
              variant='h4'
              className={classes.mainTitle}
            >
              Iniciar sesión
            </Typography>
            <Typography>
              Si no tienes cuenta{' '}
              <RouterLink to='/user/signup'>REGÍSTRATE</RouterLink>
            </Typography>
            <ValidatorForm
              ref='form'
              className={classes.form}
              noValidate
              onSubmit={this.handleSubmit}
            >
              <TextValidator
                variant='outlined'
                margin='normal'
                required
                fullWidth
                id='user'
                label='Correo electrónico'
                name='email'
                value={this.state.email}
                onChange={this.handleChange}
                autoComplete='email'
                autoFocus
                validators={['required', 'isEmail']}
                errorMessages={[
                  'Este campo es requerido',
                  'El correo electrónico no es válido',
                ]}
                className={classes.field}
              />
              <TextValidator
                variant='outlined'
                margin='normal'
                required
                fullWidth
                name='password'
                value={this.state.password}
                onChange={this.handleChange}
                label='Contraseña'
                type='password'
                id='password'
                autoComplete='current-password'
                validators={['required']}
                errorMessages={['Este campo es requerido']}
                className={classes.field}
              />
              {/* <FormControlLabel
                control={<Checkbox value="remember" color="primary" />}
                label="Remember me"
              /> */}
              <Button
                type='submit'
                variant='contained'
                color='primary'
                className={`${classes.submit} btn-primary`}
              >
                Iniciar sesión
              </Button>
              <Box mt={5}>
                <Footer className={classes.footer} />
              </Box>
            </ValidatorForm>
          </div>
        </Grid>
      </Grid>
    );
  }
}

const mapStateToProps = (state) => ({
  register: state.register,
  authentication: state.authentication,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      login: userActions.login,
      logout: userActions.logout,
      clearAlert: userActions.clearAlert,
      showAlert,
    },
    dispatch
  );

const connectedLogin = connect(mapStateToProps, mapDispatchToProps)(Login);
export default withStyles(Styles)(connectedLogin);
