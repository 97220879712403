import { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { userActions } from '../../store/actions';

class Verify extends Component {
  componentDidMount() {
    const { verify, match } = this.props;
    verify(match.params.token);
  }

  render() {
    return false;
  }
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      verify: userActions.verify,
    },
    dispatch
  );

export default connect(null, mapDispatchToProps)(Verify);
