import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { Typography, Link } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    marginTop: 'auto',
  },
  copyright: {
    display: 'flex',
    alignItems: 'center',
  },
  logo: {
    height: '45px',
  },
}));

const Footer = (props) => {
  const { className, ...rest } = props;

  const classes = useStyles();

  return (
    <div {...rest} className={clsx(classes.root, className)}>
      <Typography variant='body1'>
        <Link
          className={classes.copyright}
          href='https://innovapues.com/'
          target='_blank'
        >
          &copy; {new Date().getFullYear()} Creado por
          <img
            className={classes.logo}
            src='/images/logos/innovapues.png'
            alt='innovapues.com'
          />
        </Link>
      </Typography>
    </div>
  );
};

Footer.propTypes = {
  className: PropTypes.string,
};

export default Footer;
